<template>
	<div class="error">
		<img :src="require('@/assets/404.png')" alt="404">
		<div class="error__wrapper">
			<div class="error__title">404</div>
			<div class="error__subtitle">ERROR</div>
			<div class="error__caption">Page not found...</div>

			<router-link to="/" class="button">
				Back to main
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'page-error'
};
</script>

<style lang="scss">
	.error {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
		min-height: 100vh;
		padding: 0 47px;
		text-align: center;
		background: linear-gradient(180deg, #FFFFFF 42.19%, #EFFFFF 100%);

		img {
			@media (min-width: 769px) {
				transform: translateY(115px);
			}

			width: 100%;
			max-width: 598px;
		}

		&__title {
			font-size: 219px;
			line-height: 1;
			font-weight: 800;

			@media (max-width: 768px) {
				font-size: 70px;
			}
		}

		&__subtitle {
			margin: 0 0 9px 0;
			font-size: 60px;
			line-height: 1;
			font-weight: 800;

			@media (max-width: 768px) {
				font-size: 24px;
			}
		}

		&__caption {
			margin: 0 0 32px 0;
			font-size: 22px;
			line-height: 1;
			color: #667085;
			opacity: 0.8;

			@media (max-width: 768px) {
				font-size: 14px;
			}
		}

		&__wrapper {
			width: 100%;
			transform: translateY(-25%);
			@media (max-width: 768px) {
				transform: translateY(-50%);
			}
		}

		.button {
			max-width: 280px;
			margin: 0 auto;
		}
	}
</style>
